const getCRAEnv = (key) => {
    try {
        return process.env[`REACT_APP_${key}`];
    }
    catch (error) {
        return undefined;
    }
};
const getViteEnv = (key) => {
    try {
        return import.meta.env[`VITE_${key}`];
    }
    catch (error) {
        return undefined;
    }
};
export { getCRAEnv, getViteEnv };
