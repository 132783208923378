import { BrowserTracing, ErrorBoundary, Replay, init, reactRouterV6Instrumentation, withErrorBoundary, withSentryReactRouterV6Routing, } from '@sentry/react';
import { useEffect } from 'react';
import { Routes, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { getCRAEnv, getViteEnv } from './utils';
function initErrorTracking() {
    const dsn = getCRAEnv('SENTRY_DSN') || getViteEnv('SENTRY_DSN');
    const target = getCRAEnv('SENTRY_TARGET') || getViteEnv('SENTRY_TARGET') || 'localhost';
    const routingInstrumentation = reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes);
    const integrations = [new BrowserTracing({ routingInstrumentation }), new Replay()];
    const tracePropagationTargets = [target];
    const tracesSampleRate = 0.1;
    const replaysSessionSampleRate = 0.1;
    const replaysOnErrorSampleRate = 1.0;
    init({
        dsn,
        integrations,
        tracePropagationTargets,
        tracesSampleRate,
        replaysSessionSampleRate,
        replaysOnErrorSampleRate,
    });
}
const ErrorTrackingRoutes = withSentryReactRouterV6Routing(Routes);
export { initErrorTracking, ErrorTrackingRoutes, ErrorBoundary, withErrorBoundary };
